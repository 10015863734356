import { defineQuery, groq } from 'next-sanity'

const IMAGE_PROJECTION = /* groq */ `
  ..., // this will ensure you keep the existing data
  ...asset-> {
    altText,
    caption,
    ...metadata {
      lqip, // the lqid can be used for blurHashUrl or other low-quality placeholders
      ...dimensions {
        width,
        height
      }
    }
  }
`

const SEO_PROJECTION = /* groq */ `
  metaTitle,
  metaDescription,
  ogImage {
    ${IMAGE_PROJECTION}
  }
`

const LINK_INTERNAL_PROJECTION = /* groq */ `
  _type,
  reference-> {
    _type,
    'slug': slug.current,
    title,
    category,
    'parentPageSlug': parentPage->slug.current,
  }
`

const LINK_EXTERNAL_PROJECTION = /* groq */ `
  _type,
  label,
  url,
  newWindow,
`

const CALL_TO_ACTION_PROJECTION = /* groq */ `
  _type,
  label,
  linkType,
  linkInternal {
    ${LINK_INTERNAL_PROJECTION}
  },
  linkExternal {
    ${LINK_EXTERNAL_PROJECTION}
  },
  linkEmail {...},
  linkProduct {...}
`

const VIDEO_PROJECTION = /* groq */ `
  _type,
  asset-> {
    _type,
    playbackId,
    filename,
  }
`

const FLASH_TAG_PROJECTION = /* groq */ `
  _id,
  _type,
  title,
  color
`

const MEDIA_ONLY_PROJECTION = /* groq */ `
  _key,
  _type,
  mediaType,
  image {
    ${IMAGE_PROJECTION}
  },
  video {
    ${VIDEO_PROJECTION}
  },
  thumbnailTime,
  'nativeAspectRatio': select(
    mediaType == 'image' => string(image.asset->metadata.dimensions.height) + ':' + string(image.asset->metadata.dimensions.width),
    mediaType == 'video' => video.asset->data.aspect_ratio
)
`

// const MEDIA_ONLY_PROJECTION = /* groq */ `
//   _key,
//   _type,
//   mediaType,
//   image {
//     ${IMAGE_PROJECTION}
//   },
//   video {
//     ${VIDEO_PROJECTION}
//   }
// `

const FILE_PROJECTION = /* groq */ `
  _type,
  'url': asset->url,
  'filename': asset->originalFilename,
`

const FILE_WITH_NAME_PROJECTION = /* groq */ `
  _type,
  name,
  file {
    ${FILE_PROJECTION}
  }
`

const MEDIA_PROJECTION = /* groq */ `
  ${MEDIA_ONLY_PROJECTION},
  border,
  aspectRatio,
  caption {
    ...
  }
`

// const LINK_FIELD_PROJECTION = /* groq */ `
//   ...,
//   internalLink->{_type,slug,title}
// `

const PORTABLE_TEXT_PROJECTION = /* groq */ `
  ...,
  markDefs[]{
    ...,
    _type == "linkEmail" => {...},
    _type == "linkInternal" => {
      ${LINK_INTERNAL_PROJECTION}
    },
    _type == "linkExternal" => {
      ${LINK_EXTERNAL_PROJECTION}
    },
    _type == "linkPhone" => {...},
    _type == "linkProduct" => {...},
    _type == 'tooltipOverlay' => {...},
  }
`

const SHARED_COPY_PROJECTION = /* groq */ `
  _type,
  subtitle,
  title,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const PRODUCT_PROJECTION = /* groq */ `
  _id,
  _type,
  'title': store.title,
  'slug': store.slug.current,
  store {
    previewImageUrl,
    priceRange {
      minVariantPrice,
      maxVariantPrice
    }
  },
  flashTag->{
    ${FLASH_TAG_PROJECTION}
  }
`

const POST_PROJECTION = /* groq */ `
  _id,
  _type,
  title,
  'slug': slug.current,
  excerpt,
  featuredMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  category,
  'subCategory': subCategories[0]->title,
  flashTag-> {
    ${FLASH_TAG_PROJECTION}
  },
  featuredMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  color,
  textColor,
  times {
    totalTime
  }
`
const EVENT_PROJECTION = /* groq */ `
  _id,
  _type,
  title,
  'slug': slug.current,
  textColor,
  excerpt,
  date,
  address,
  availabilityText,
  eventCta {
    ${CALL_TO_ACTION_PROJECTION}
  },
  time,
  featuredMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  color,
`
const INTRO_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  title,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  medias[] {
    ${MEDIA_PROJECTION}
  }
`
const CONTACTUS_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  title,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  enquiries {
    title,
    copy[] {
      ${PORTABLE_TEXT_PROJECTION}
    }
  },
  contactCafe {
    title,
    copy[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    contactCafeList[] {
      id,
      title,
      slug,
      address,
      googleMapsLink,
      phone,
      times,
      planCta {
      ${CALL_TO_ACTION_PROJECTION}
    },
      bookCta {
      ${CALL_TO_ACTION_PROJECTION}
    },
      image {
         ${MEDIA_PROJECTION}
      }
},
  },
  chitChat {
    title,
    copy[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    cta {
      ${CALL_TO_ACTION_PROJECTION}
    }
  },
  followUsLinks {
    title,
    socialLinks[] {
      ${CALL_TO_ACTION_PROJECTION}
    }
  }
`

const KEY_PROPOSITIONS_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  subtitle,
  title,
  cards[] {
    title,
    excerpt,
    previewImage {
      ${IMAGE_PROJECTION}
    },
    hoverVideo {
      ${VIDEO_PROJECTION}
    },
    cta {
      ${CALL_TO_ACTION_PROJECTION}
    }
  }
`

const CAFE_LIST_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  backgroundVideo {
    ${VIDEO_PROJECTION}
  },
  title,
  copy,
  cafesTitle,
  cafes[]-> {
    _id,
    _type,
    title,
    "slug": slug.current,
  },
  permitRoomsTitle,
  permitRooms[] {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const CAFE_TEXT_MEDIA_PROJECTION = /* groq */ `
  _type,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  },
  isReversed
`

const CAFE_SPECIALS_TEXT_MEDIA_PROJECTION = /* groq */ `
  _type,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  },
  isReversed
`

const CAFE_HERO_PROJECTION = /* groq */ `
  _type,
  title,
  subtitle,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  mobileMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  branding {
    ${IMAGE_PROJECTION}
  }
`

const CAFE_GALLERY_PROJECTION = /* groq */ `
  _type,
  title,
  copy,
  ctaLabel,
  backgroundMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  galleryImages[] {
    ${MEDIA_PROJECTION}
  }
`

const VISITOR_INFO_PROJECTION = /* groq */ `
  _type,
  googleMaps {
    ${IMAGE_PROJECTION}
  },
  mapImage {
    ${IMAGE_PROJECTION}
  },
  googleMapsLink {
    ${CALL_TO_ACTION_PROJECTION}
  },
  locationGeopoint {...},
  mapMarkerLabel,
  address,
  nearestStation,
  contact,
  locationAmenities,
  visitorInfoItems[] {
    title,
    textBlock[] {
      ${PORTABLE_TEXT_PROJECTION}
    }
  }
`

export const FULL_WIDTH_MEDIA_COPY_PROJECTION = /* groq */ `
  _type,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  textBlock[] {
    ${PORTABLE_TEXT_PROJECTION}
  }
`

const RESERVATIONS_PROJECTION = /* groq */ `
  _type,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  linkTable {
    ${CALL_TO_ACTION_PROJECTION}
  },
  linkBookings {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const ORIGIN_STORY_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  subtitle,
  title,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  mobileLayout,
  desktopLayout,
  color,
  textColor,
  media {
    ${MEDIA_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const SECTION_FULL_HEADER_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  title,
  variant,
  fullWidthHeaderMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  fullWidthHeaderSize,
  sectionSubtitle,
  sectionTitle,

  sectionCopyTop[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  sectionCopyBottom[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  sectionCtaPrimary {
    ${CALL_TO_ACTION_PROJECTION}
  },
  sectionCtaSecondary {
    ${CALL_TO_ACTION_PROJECTION}
  },
  sectionMedias[] {
    ${MEDIA_PROJECTION}
  },
  banner {
    _type,
    subtitle,
    title,
    isDisabled,
    copy,
    cta {
      ${CALL_TO_ACTION_PROJECTION}
    },
    media {
      ${MEDIA_PROJECTION}
    }
  },
  products[]->{
      ${PRODUCT_PROJECTION}
  },
`

const STORE_INTRO_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  contentPosition,
  subtitle,
  title,
  copy,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const PERMIT_ROOM_PROMO_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  subtitle,
  title,
  copy,
  media {
    ${MEDIA_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const IMPACT_MODULE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  sections[] {
    _key,
    textColor,
    color,
    subtitle,
    title,
    copy,
    cta {
      ${CALL_TO_ACTION_PROJECTION}
    },
    media {
      ${MEDIA_PROJECTION}
    }
  }
`

const CTA_BANNER_PROJECTION = /* groq */ `
    _type,
    isDisabled,
    subtitle,
    title,
    copy,
    cta {
      ${CALL_TO_ACTION_PROJECTION}
    },
    media {
      ${MEDIA_PROJECTION}
    }
`

const CAREERS_MODULE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  fullWidthMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  copyBlock {
    ${SHARED_COPY_PROJECTION}
  },
  sections[] {
    _key,
    media {
      ${MEDIA_PROJECTION}
    },
    sectionCopyBlock {
      ${SHARED_COPY_PROJECTION}
    }
  }
`

const AWARDS_AND_ACHIEVEMENTS_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  copyBlock {
    ${SHARED_COPY_PROJECTION}
  },
  featuredAwards[] -> {
    _id,
    title,
    subtitle,
    year,
    copy,
    style,
    image {
      ${IMAGE_PROJECTION}
    }
  },
  'awards': *[_type == 'award'] {
    _id,
    title,
    subtitle,
    year,
    copy,
    style,
    image {
      ${IMAGE_PROJECTION}
    }
  }
`

const PULL_QUOTE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  quote,
`
const PULL_QUOTE_SLIDER_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  quotes[]{
    ${PORTABLE_TEXT_PROJECTION},
    author,
  },
`

const FOUNDING_MYTHS_SLIDER_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  headerCopy {
    ${SHARED_COPY_PROJECTION}
  },
  myths[] -> {
    ${POST_PROJECTION},
    color,
    textColor,
  }
`

// BLOG BLOCKS
const GALLERY_SLIDER_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  title,
  subtitle,
  layout,
  medias[] {
    ${MEDIA_PROJECTION}
  }
`

const GALLERY_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  medias[] {
    ${MEDIA_PROJECTION}
  }
`

const VIDEOS_BLOCK_PROJECTION = /* groq */ `
  _type,
  tag,
  title,
  copy,
  videos[] {
    videoTitle,
    media {
      ${MEDIA_ONLY_PROJECTION}
    }
  }
`

const COLLECTION_CTA_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  backgroundMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  tag,
  title,
  cardMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  link {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const RELATED_GUIDE_CONTENT_PROJECTION = /* groq */ `
  _type,
  backgroundMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  tag,
  links[] {
    ${CALL_TO_ACTION_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const CURATED_ROW_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  postType,
  subtitle,
  title,
  variant,
  categories,
  subCategories[],
  tags[],
  'posts': *[
    _type == "post"
    && ^.postType == "posts"
    && (!defined(^.categories) || category in ^.categories)
    && (!defined(^.subCategories) || count((subCategories[]->title)[@ in ^.subCategories[]->title]) == count(^.subCategories))
    && (!defined(^.tags) || count((tags[]->title)[@ in ^.tags[]->title]) == count(^.tags))
  ] | order(orderRank) [0..8]  {
    ${POST_PROJECTION}
  },
  'products': *[
    _type == "product"
    && ^.postType == "products"
    && (!defined(^.tags) || count((tags[]->title)[@ in ^.tags[]->title]) == count(^.tags))
  ] | order(orderRank) [0..8] {
    ${PRODUCT_PROJECTION}
  },
  featuredPosts[] -> {
    ${POST_PROJECTION}
  },
  featuredProducts[] -> {
    ${PRODUCT_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  },
  manualCards[] {
    title,
    copy,
    image {
      ${IMAGE_PROJECTION}
    }
  }
`

const TEXT_BLOCK_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  topDivider,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  }
`

const SIMPLE_TITLE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  title,
  subtitle
`

const TICKET_CARD_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  subtitle,
  title,
  event-> {
    ${EVENT_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const BLOG_MEDIA_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  fullWidth,
  media {
    ${MEDIA_PROJECTION}
  }
`

const RESOURCES_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  title,
  copy,
  style,
  backgroundMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  files[] {
    ${FILE_WITH_NAME_PROJECTION}
  }
`

const FULL_WIDTH_FEATURE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  post-> {
    ${POST_PROJECTION}
  },
  secondaryMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  layout,
  variant,
  cursorTooltip,
  tooltipCaption
`

const NEXT_CHAPTER_PROJECTION = /* groq */ `
  _type,
  nextChapter-> {
    ${POST_PROJECTION}
  }
`

const FULL_WIDTH_MOOD_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  text,
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const PRODUCT_STORY_PROJECTION = /* groq */ `
  _type,
  productStory-> {
    _id,
    subtitle,
    title,
    variant,
    media {
      ${MEDIA_ONLY_PROJECTION}
    },
    copy[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    cta {
      ${CALL_TO_ACTION_PROJECTION}
    },
    bottomLineCopy,
    productSliderHeader,
    products[]-> {
      ${PRODUCT_PROJECTION}
    },
    cursorTooltip,
    tooltipCaption
  }
`

const HERO_CTA_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  variant,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  subtitle,
  title,
  copy,
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

export const TIPS_OVERLAY_PROJECTION = /* groq */ `
  _type,
  label,
  tips[] {
    _key,
    _type,
    subtitle,
    title,
    copy,
    image {
      ${IMAGE_PROJECTION}
    },
  }
`

const METHOD_AND_INGREDIENTS_PROJECTION = /* groq */ `
  _type,
  servingsCount,
  ingredients[] {
    sectionTitle,
    ingredients[] {
      amount,
      unit,
      name,
      _key,
    }
  },
  productSections[] {
    sectionTitle,
    description,
    products[]-> {
      ${PRODUCT_PROJECTION}
    }
  },
  content[] {
    _key,
    _type == 'textBlock' => {
      ${TEXT_BLOCK_PROJECTION}
    },
    _type == 'blogMedia' => {
      ${BLOG_MEDIA_PROJECTION}
    },
    _type == 'blogDivider' => {
      _type,
    },
    _type == 'tipsOverlay' => {
      ${TIPS_OVERLAY_PROJECTION}
    },
  }
`

const FEATURE_AND_LIST_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  subtitle,
  title,
  variant,
  'posts': *[
    _type == "post"
    && (!defined(^.categories) || category in ^.categories)
    && (!defined(^.subCategories) || count((subCategories[]->title)[@ in ^.subCategories[]->title]) == count(^.subCategories))
    && (!defined(^.tags) || count((tags[]->title)[@ in ^.tags[]->title]) == count(^.tags))
  ] | order(orderRank) [0..8] {
    ${POST_PROJECTION}
  },
  featuredPost-> {
    ${POST_PROJECTION}
  },
  postsList[]-> {
    ${POST_PROJECTION}
  },
  link {
    ${LINK_INTERNAL_PROJECTION}
  }
`

const FORM_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  subtitle,
  title,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  selectedForm,
  media {
    ${MEDIA_ONLY_PROJECTION}
  }
`

const FOUNDING_MYTHS_SCROLLER_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  myths[] -> {
    ${POST_PROJECTION},
    color,
    textColor,
  },
  cursorTooltip,
  tooltipCaption
`

const CARD = `
_type,
featureHeading,
featureDescription,
tooltip,
color,
products[]->{
store{
slug,
priceRange,
title,
previewImageUrl
},
},
cta,
`
const FEATURE_PRODUCTS_BLOCK = `
_type,
isDisabled,
title,
backgroundMedia{
  ${MEDIA_ONLY_PROJECTION}
},
Card{
${CARD}
}
`

const FULL_WIDTH_GUIDE_FEATURE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  tag,
  quote,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  ctaSubtitle,
  ctaTitle,
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

const FEATURED_POST_BLOCK_TYPE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  featuredPost -> {
    ${POST_PROJECTION}
  }
`

const CONTENT_BUCKET = /* groq */ `
  _type,
  isDisabled,
  logo {
    ${IMAGE_PROJECTION}
  },
  seoTitle,
  description,
  layout,
  variant,
  link {
    ${CALL_TO_ACTION_PROJECTION}
  },
  featuredPosts[] -> {
    ${POST_PROJECTION}
  },
  'posts': *[
    _type == "post"
    && (!defined(^.categories) || category in ^.categories)
    && (!defined(^.subCategories) || count((subCategories[]->title)[@ in ^.subCategories[]->title]) == count(^.subCategories))
    && (!defined(^.tags) || count((tags[]->title)[@ in ^.tags[]->title]) == count(^.tags))
  ] | order(orderRank) [0..8] {
    ${POST_PROJECTION}
  },
`

const MAP_GUIDE_PROJECTION = /* groq */ `
  _type,
  googleMapsLink {
    ...
  },
  sections[] {
    _key,
    title,
    locations[] {
      locationGeopoint {...},
      locationName,
      locationOpeningTimes,
      locationLinks[] {
        _type == 'callToAction' => {
          ${CALL_TO_ACTION_PROJECTION}
        }
      },
      locationDescription[] {
        _key,
        _type == 'textBlock' => {
          ${TEXT_BLOCK_PROJECTION}
        },
        _type == 'blogMedia' => {
          ${BLOG_MEDIA_PROJECTION}
        },
        _type == 'blogDivider' => {
          _type,
        },
      }
    }
  }
`

const HEADER_NAV_PROJECTION = /* groq */ `
  primaryNavLayout,
  primaryNav[] {
    subtitle,
    links[] {
      _type == 'callToAction' => {
        ${CALL_TO_ACTION_PROJECTION}
      },
      _type == 'dropdown' => {
        _type,
        title,
        links[] {
          _type == 'callToAction' => {
            ${CALL_TO_ACTION_PROJECTION}
          },
        }
      }
    }
  },
  secondaryNav[] {
    _key,
    _type == 'callToAction' => {
      ${CALL_TO_ACTION_PROJECTION}
    },
  },
  hideSecondaryNavCTAs,
  featureType,
  featureCard {
    title,
    copy,
    media {
      ${MEDIA_ONLY_PROJECTION}
    },
    link {
      ${CALL_TO_ACTION_PROJECTION}
    }
  },
  featureProducts {
    title,
    products[] ->{
      ${PRODUCT_PROJECTION}
    }
  }
`

let DISH_PROJECTION = /* groq */ `
  _id,
  _type,
  name,
  description,
  Kcal,
  allergens[]-> {
    title,
  },
  isVegetarian,
  isVegan,
  isSpicy,
  image {
    ${IMAGE_PROJECTION}
  },
  additionalStory[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  locations[]-> {
    _id,
    _type,
    title,
    "slug": slug.current,
  },
  price,
  priceOverride[] {
    location-> {
      _id,
      _type,
      title,
      "slug": slug.current,
    },
    price
  },
  visibleOnParentMenu,
`
DISH_PROJECTION += `
  veganOverride->{
    ${DISH_PROJECTION}
  },
`
const FAQ_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  title,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
   categories[] {
    _type,
    title,
    faqs[] {
      _type,
      question,
      copy[] {
        ${PORTABLE_TEXT_PROJECTION}
      },
    }
  }
`
const PARENT_MENU_PROJECTION = /* groq */ `
  'slug': slug.current,
  menuListing[] {
   _type,
   title,
   description,
   subSections[] {
      _type,
      title,
      description,
      items[] {
        _type == 'menuDish' => {
          _type,
          dish->{
            ${DISH_PROJECTION}
          },
          featuredDish
        },
        _type == 'menuStory' => {
          _type,
          image {
            ${IMAGE_PROJECTION}
          },
          copy,
          readMoreLink {
            ${LINK_INTERNAL_PROJECTION}
          }
        }
      }
    },
  },
  locations[] {
    cafe-> {
      _id,
      title,
      "slug": slug.current
    },
    locationMenu-> {
      _id,
      title,
      "slug": slug.current
    }
  }
`

const MENU_PAYLOAD_PROJECTION = /* groq */ `
  _id,
  title,
  "slug": slug.current,
  isParent,
  locations[] {
    cafe-> {
      _id,
      title,
      "slug": slug.current,
    },
    locationMenu-> {
      _id,
      title,
      "slug": slug.current,
    },
  },
  isParent == false => {
    parentMenu->{
      ${PARENT_MENU_PROJECTION}
    },
  },
  currentLocation-> {
    _id,
    title,
    "slug": slug.current,
  },
  pageTitleSEO,
  heroSubtitle,
  heroCopy,
  heroTitle,
  heroMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  menuIntro {
    title,
    summary[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    description[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    keyInfo[] {
        title,
        description
      },
    addCtas,
    primaryCta {
      ${CALL_TO_ACTION_PROJECTION}
    },
    secondaryCta {
      ${CALL_TO_ACTION_PROJECTION}
    },
  },
  menuLayout,
  menuListing[] {
    _type,
    title,
    description,
    subSections[] {
      _type,
      title,
      description,
      items[] {
        _type == 'menuDish' => {
          _type,
          dish->{
            ${DISH_PROJECTION}
          },
          featuredDish
        },
        _type == 'menuStory' => {
          _type,
          image {
            ${IMAGE_PROJECTION}
          },
          copy,
          readMoreLink {
            ${LINK_INTERNAL_PROJECTION}
          }
        }
      }
    }
  },
  menuTerms[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  'allergens': *[_type == 'allergen'] {
    _id,
    title,
  },
  seo {
    ${SEO_PROJECTION}
  },
`

export const STORE_HERO_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  storeSlides[]{
    variant,
    heading,
    subHeading,
    backgroundMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    withAnnouncementCard,
    announcementHeadline,
    announcementTitle[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    announcementDescription[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    announcementToolTip,
    announcementCTA {
      ${CALL_TO_ACTION_PROJECTION}
    },
    cta {
      ${CALL_TO_ACTION_PROJECTION}
    }
  }
`

const ACCORDION_PROJECTION = /* groq */ `
  _type,
  rows[] {
    _key,
    title,
    copy[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    media {
      ${MEDIA_PROJECTION}
    }
  }
`

const SINGLE_MEDIA_CTA_PROJECTION = /* groq */ `
  _type,
  title,
  copy[] {
    ${PORTABLE_TEXT_PROJECTION}
  },
  media {
    ${MEDIA_PROJECTION}
  },
  cta {
    ${CALL_TO_ACTION_PROJECTION}
  }
`

export const AUDIO_PLAYLIST_BLOCK_PROJECTION = /* groq */ `
  title,
  subtitle,
  tracks[] {
    trackTitle,
    artistName,
    trackThumbnail,
    "audio": audio.asset->{
      uploadId,
      _id,
      extension,
      url,
      mimeType,
      originalFilename
    }
  }
`

const COMMON_PRODUCT_FIELDS_PROJECTION = /* groq */ `
  vendor,
  status,
  id,
  "slug": slug.current,
  gid,
  tags,
  previewImageUrl,
  productType,
  price,
  priceRange {
    maxVariantPrice,
    minVariantPrice
  },
  createdAt,
  title,
  descriptionHtml,
  isDeleted
`

const COLLECTIONS_SPOTLIGHT_PROJECTION = /* groq */ `
  _type,
  heading,
  subHeading,
  description[]{
    ${PORTABLE_TEXT_PROJECTION}
  },
  collections[]{
    content{
      images[]{
        image{
        hoverImage{
          ${IMAGE_PROJECTION}
        },
        previewImage{
          ${IMAGE_PROJECTION}
        },
        }
      },
      title
    }
  },
  cta{
    ${CALL_TO_ACTION_PROJECTION}
  }
`

export const PRODUCT_STORE_FIELDS_PROJECTION = /* groq */ `
  store {
    ${COMMON_PRODUCT_FIELDS_PROJECTION},
    variants[]->{
      store{
        ${COMMON_PRODUCT_FIELDS_PROJECTION},
      },
    },
  }
`

export const COLLECTION_SLIDER_PROJECTION = /* groq */ `
  _type,
  preHeader,
  heading[]{
    ${PORTABLE_TEXT_PROJECTION}
  },
  collectionCards[]{
    _type,
    collectionName,
    image{
      ${MEDIA_ONLY_PROJECTION}
    },
  }
`

const LIFESTYLE_SHOPPING_MODULE_PROJECTION = /* groq */ `
  _type,
  subHeading,
  heading,
  backgroundMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  numberOfHotspots,
  hotspotOne->{
    ${PRODUCT_PROJECTION}
  },
  hotspotTwo->{
    ${PRODUCT_PROJECTION}
  },
  hotspotThree->{
    ${PRODUCT_PROJECTION}
  },
  hotspotFour->{
    ${PRODUCT_PROJECTION}
  },
`

const CAFE_MYTH_TEASER_PROJECTION = /* groq */ `
  _id,
  _type,
  title,
  'slug': slug.current,
  excerpt,
  featuredMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  featuredMedia {
    ${MEDIA_ONLY_PROJECTION}
  },
  color,
  textColor,
  readingTime,
  storyAudio {
    ${FILE_PROJECTION}
  },
  content[] {
    _key,
    _type == 'textBlock' => {
      ${TEXT_BLOCK_PROJECTION}
    },
    _type == 'blogDivider' => {
      _type,
    },
    _type == 'blogMedia' => {
      ${BLOG_MEDIA_PROJECTION}
    },
    _type == 'nextChapter' => {
      ${NEXT_CHAPTER_PROJECTION}
    },
    _type == "gallerySlider" => {
      ${GALLERY_SLIDER_PROJECTION}
    },
    _type == 'gallery2up3up' => {
      _type,
      medias[] {
        ${MEDIA_PROJECTION}
      }
    },
    _type == 'gallery' => {
      ${GALLERY_PROJECTION}
    },
    _type == 'heroCta' => {
      ${HERO_CTA_PROJECTION}
    },
    _type == 'fullWidthMood' => {
      ${FULL_WIDTH_MOOD_PROJECTION}
    },
  },
`

export const FOUNDING_MYTH_TEASER_PROJECTION = /* groq */ `
  _type,
  image {
    ${IMAGE_PROJECTION}
  },
  title,
  copy,
  myth-> {
     ${CAFE_MYTH_TEASER_PROJECTION}
  }
`

export const PRODUCT_GRID_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  subtitle,
  title,
  'products': products[] {
    _type == "reference" => @-> {
      ${PRODUCT_PROJECTION}
    },
    _type != 'reference' => {
      _type,
      title,
      price,
      image {
        ${IMAGE_PROJECTION}
      },
      cta {
        ${CALL_TO_ACTION_PROJECTION}
      }
    }
  }
`

export const PLAYLIST_MODULE_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  _key,
  heading,
  subHeading,
  modulePreHeading,
  moduleHeading,
  backgroundMedia{
    ${MEDIA_PROJECTION}
  },
  playlistTrack[]{
    _type,
    audio{
      ${FILE_PROJECTION}
    },
    trackName,
    artistName,
    thumbnailImage{
      ${IMAGE_PROJECTION}
    },
  }
`

const SOCIAL_CAROUSEL = /* groq */ `
  _type,
  isDisabled,
  heading,
  cta{
    ${CALL_TO_ACTION_PROJECTION}
  },
  feeds[]{
    ${MEDIA_PROJECTION},
  }
`
export const PARTNERS_COLLECTION_PROJECTION = `
  _type,
   isDisabled,
  variant,
  heading,
  color,
  variant == "primary" => {
    intro{
  backgroundMedia{
   ${MEDIA_ONLY_PROJECTION}
  },
  collectionBranding{
   ${MEDIA_ONLY_PROJECTION}
  }
  },
  subtitle[]{
  ${PORTABLE_TEXT_PROJECTION}
  },
  bodyCopy[]{
  ${PORTABLE_TEXT_PROJECTION}
  },
  primaryCta{
  ${CALL_TO_ACTION_PROJECTION}
  },
  secondaryCta{
  ${CALL_TO_ACTION_PROJECTION}
  },
  mainMedia{
    ${MEDIA_PROJECTION}
  },
  },
  variant == "secondary" => {
  leadImage{
     ${MEDIA_ONLY_PROJECTION}
  },
  branding{
   ${MEDIA_ONLY_PROJECTION}
  },
  secondaryHeading[]{
  ${PORTABLE_TEXT_PROJECTION}
  },
  cta{
   ${CALL_TO_ACTION_PROJECTION}
  }
  },
  productsSlider[]->{
   ${PRODUCT_PROJECTION}
  },
`

export const NEWSLETTER_CTA_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  media {
    ${MEDIA_ONLY_PROJECTION}
  },
  text
`

export const CREATE_AT_HOME_CALLOUT_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  image {
    ${IMAGE_PROJECTION}
  },
  subtitle,
  title,
  productHotspots[]{
    _key,
    _type,
    x,
    y,
    productWithVariant {
      product->{
        ${PRODUCT_PROJECTION}
      },
      _type
    }
  }
`

export const homePageQuery = defineQuery(`
  *[_type == "home"][0]{
    _id,
    homeHero[] {
      title,
      secondaryTitle,
      timeOfDay,
      media {
        mediaType,
        image {
          ${IMAGE_PROJECTION}
        },
        video {
          ${VIDEO_PROJECTION}
        }
      }
    },
    content[] {
      _key,
      _type == "intro" => {
        ${INTRO_PROJECTION}
      },
      _type == "keyPropositions" => {
        ${KEY_PROPOSITIONS_PROJECTION}
      },
      _type == "cafeList" => {
        ${CAFE_LIST_PROJECTION}
      },
      _type == "originStory" => {
        ${ORIGIN_STORY_PROJECTION}
      },
      _type == "sectionFullHeader" => {
        ${SECTION_FULL_HEADER_PROJECTION}
      },
      _type == "storeIntro" => {
        ${STORE_INTRO_PROJECTION}
      },
      _type == "permitRoomPromo" => {
        ${PERMIT_ROOM_PROMO_PROJECTION}
      },
      _type == "impactModule" => {
        ${IMPACT_MODULE_PROJECTION}
      },
      _type == "careersModule" => {
        ${CAREERS_MODULE_PROJECTION}
      },
      _type == "awardsAndAchievements" => {
        ${AWARDS_AND_ACHIEVEMENTS_PROJECTION}
      },
      _type == "foundingMythsSlider" => {
        ${FOUNDING_MYTHS_SLIDER_PROJECTION}
      },
      _type == "curatedRow" => {
        ${CURATED_ROW_PROJECTION}
      },
      _type == 'fullWidthFeature' => {
        ${FULL_WIDTH_FEATURE_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
      _type == 'featureAndList' => {
        ${FEATURE_AND_LIST_PROJECTION}
      },
      _type == 'foundingMythsScroller' => {
        ${FOUNDING_MYTHS_SCROLLER_PROJECTION}
      },
      _type == 'fullWidthGuideFeature' => {
        ${FULL_WIDTH_GUIDE_FEATURE_PROJECTION}
      },
      _type == 'productGrid' => {
        ${PRODUCT_GRID_PROJECTION}
      },
      _type == 'newsletterCTA' => {
        ${NEWSLETTER_CTA_PROJECTION}
      },
      _type == 'simpleTitle' => {
        ${SIMPLE_TITLE_PROJECTION}
      },
      _type == 'ticketCard' => {
        ${TICKET_CARD_PROJECTION}
      }
    },
    seo {
      ${SEO_PROJECTION}
    }
  }
`)

export const allLocationsSinglePageQuery = defineQuery(`
  *[_type == "allLocations"][0]{
    _id,
    heroSubtitle,
    heroTitle,
    heroCopy[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    heroMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    'cafes': *[_type == "cafe"]{
      _type,
      _id,
      title,
      "slug": slug.current,
      bookingImage,
      welcomeTo {
        ${CAFE_TEXT_MEDIA_PROJECTION}
      },
      visitorInfo {
        ${VISITOR_INFO_PROJECTION}
      },
      seo {
        ${SEO_PROJECTION}
      },
      openingTimes[] {
        day,
        openingTime,
        closingTime
      }
    },
    'permitRooms': *[_type == "permitRoom"]{
      _type,
      _id,
      title,
      "slug": slug.current,
      copy[] {
        ${PORTABLE_TEXT_PROJECTION}
      },
      bookingImage,
      bookingUrl,
      openingTimes[] {
        day,
        openingTime,
        closingTime
      },
      googleMapsLink {
        ${CALL_TO_ACTION_PROJECTION}
      },
      locationGeopoint {...},
      mapMarkerLabel,
      address,
      nearestStation,
      contact,
      locationAmenities
    },
    content[] {
      _key,
      _type == 'textBlock' => {
        ${TEXT_BLOCK_PROJECTION}
      },
    },
    seo {
      ${SEO_PROJECTION}
    }
  }
`)

export const storePageQuery = defineQuery(`
  *[_type=="store"][0]{
    _id,
    storeHero{
     ${STORE_HERO_PROJECTION}
    },
    content[] {
      _key,
      _type == "intro" => {
        ${INTRO_PROJECTION}
      },
      _type == "keyPropositions" => {
        ${KEY_PROPOSITIONS_PROJECTION}
      },
      _type == "cafeList" => {
        ${CAFE_LIST_PROJECTION}
      },
      _type == "originStory" => {
        ${ORIGIN_STORY_PROJECTION}
      },
      _type == "sectionFullHeader" => {
        ${SECTION_FULL_HEADER_PROJECTION}
      },
      _type == "storeIntro" => {
        ${STORE_INTRO_PROJECTION}
      },
      _type == "permitRoomPromo" => {
        ${PERMIT_ROOM_PROMO_PROJECTION}
      },
      _type == "impactModule" => {
        ${IMPACT_MODULE_PROJECTION}
      },
      _type == "careersModule" => {
        ${CAREERS_MODULE_PROJECTION}
      },
      _type == "awardsAndAchievements" => {
        ${AWARDS_AND_ACHIEVEMENTS_PROJECTION}
      },
      _type == "foundingMythsSlider" => {
        ${FOUNDING_MYTHS_SLIDER_PROJECTION}
      },
      _type == "curatedRow" => {
        ${CURATED_ROW_PROJECTION}
      },
      _type == 'fullWidthFeature' => {
        ${FULL_WIDTH_FEATURE_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
      _type == 'featureAndList' => {
        ${FEATURE_AND_LIST_PROJECTION}
      },
      _type == 'foundingMythsScroller' => {
        ${FOUNDING_MYTHS_SCROLLER_PROJECTION}
      },
      _type == 'fullWidthGuideFeature' => {
        ${FULL_WIDTH_GUIDE_FEATURE_PROJECTION}
      },
      _type == 'productGrid' => {
        ${PRODUCT_GRID_PROJECTION}
      },
      _type == 'playlistModule' => {
        ${PLAYLIST_MODULE_PROJECTION}
      },
      _type == 'socialCarousel' => {
        ${SOCIAL_CAROUSEL}
      },
      _type == 'featureProductsBlock' => {
        ${FEATURE_PRODUCTS_BLOCK}
      },
      _type == "collectionsSpotlight" => {
        ${COLLECTIONS_SPOTLIGHT_PROJECTION}
      },
      _type == 'collectionCta' => {
        ${COLLECTION_CTA_PROJECTION}
      },
       _type == 'partnerCollection' => {
        ${PARTNERS_COLLECTION_PROJECTION}
      },
      _type == 'pullQuoteSlider' => {
        ${PULL_QUOTE_SLIDER_PROJECTION}
      },
      _type == 'simpleTitle' => {
        ${SIMPLE_TITLE_PROJECTION}
      },
      _type == 'ticketCard' => {
        ${TICKET_CARD_PROJECTION}
      }
    },
    seo {
      ${SEO_PROJECTION}
    }
  }
`)

export const journalPageQuery = defineQuery(`
  *[_type == "journal"][0]{
    _id,
    heroTitle,
    heroCopy,
    heroMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    featuredStory-> {
      ${POST_PROJECTION}
    },
    content[] {
      _key,
      _type == "intro" => {
        ${INTRO_PROJECTION}
      },
      _type == "keyPropositions" => {
        ${KEY_PROPOSITIONS_PROJECTION}
      },
      _type == "cafeList" => {
        ${CAFE_LIST_PROJECTION}
      },
      _type == "originStory" => {
        ${ORIGIN_STORY_PROJECTION}
      },
      _type == "sectionFullHeader" => {
        ${SECTION_FULL_HEADER_PROJECTION}
      },
      _type == "storeIntro" => {
        ${STORE_INTRO_PROJECTION}
      },
      _type == "permitRoomPromo" => {
        ${PERMIT_ROOM_PROMO_PROJECTION}
      },
      _type == "impactModule" => {
        ${IMPACT_MODULE_PROJECTION}
      },
      _type == "careersModule" => {
        ${CAREERS_MODULE_PROJECTION}
      },
      _type == "awardsAndAchievements" => {
        ${AWARDS_AND_ACHIEVEMENTS_PROJECTION}
      },
      _type == "foundingMythsSlider" => {
        ${FOUNDING_MYTHS_SLIDER_PROJECTION}
      },
      _type == "curatedRow" => {
        ${CURATED_ROW_PROJECTION}
      },
      _type == 'fullWidthFeature' => {
        ${FULL_WIDTH_FEATURE_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
      _type == 'featureAndList' => {
        ${FEATURE_AND_LIST_PROJECTION}
      },
      _type == 'form' => {
        ${FORM_PROJECTION}
      },
      _type == 'foundingMythsScroller' => {
        ${FOUNDING_MYTHS_SCROLLER_PROJECTION}
      },
      _type == 'fullWidthGuideFeature' => {
        ${FULL_WIDTH_GUIDE_FEATURE_PROJECTION}
      },
      _type == 'contentBucket' => {
        ${CONTENT_BUCKET}
      },
      _type == 'videosBlock' => {
        ${VIDEOS_BLOCK_PROJECTION}
      },
      _type == 'collectionCta' => {
       ${COLLECTION_CTA_PROJECTION}
      },
      _type == 'relatedGuideContent' => {
       ${RELATED_GUIDE_CONTENT_PROJECTION}
      },
      _type == 'featuredPostBlock' => {
        ${FEATURED_POST_BLOCK_TYPE_PROJECTION}
      },
      _type == 'productGrid' => {
        ${PRODUCT_GRID_PROJECTION}
      },
      _type == 'simpleTitle' => {
        ${SIMPLE_TITLE_PROJECTION}
      },
      _type == 'createAtHomeCallout' => {
        ${CREATE_AT_HOME_CALLOUT_PROJECTION}
  },
      _type == 'ticketCard' => {
        ${TICKET_CARD_PROJECTION}
      }
    },
    seo {
      ${SEO_PROJECTION}
    }
  }
`)

export const journalCategoryPageQuery = defineQuery(`
  *[_type == "postCategoryPage"  && slug.current == $slug][0]{
    _id,
    heroLogo {
      ${IMAGE_PROJECTION}
    },
    heroSubtitle,
    heroTitle,
    heroCopy[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    heroMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    category,
    'subCategoryTitle': coalesce(*[_type == 'subCategory' && slug.current == $subCategorySlug][0].title, false),
    'subCategories': *[
      _type == 'subCategory' &&
      ^.category in parentCategories &&
      count(*[_type == 'post' && references(*[_type == 'subCategory' && title == ^.^.title]._id)]) > 0
    ] {
      _id,
      _type,
      title,
      'slug': slug.current,
    },
    'posts': *[
      _type == "post" && category == ^.category && $slug != 'myths' && ^.category != 'all' &&
        (
          !$subCategorySlug ||
          $subCategorySlug in subCategories[]->slug.current
        ) ||
      _type == "post" && ^.category == 'all' &&
        (
          !$subCategorySlug ||
          $subCategorySlug in subCategories[]->slug.current
        ) ||
      _type == "myth" && $slug == 'myths' ||
      _type == "event" && $slug == 'events'
    ] | order(orderRank) {
      _type == 'post' || _type == 'myth' => {
        ${POST_PROJECTION}
      },
      _type == 'event' => {
        ${EVENT_PROJECTION}
      }
    },
    categoryBreakSection {
      ${FULL_WIDTH_MOOD_PROJECTION}
    },
    breakOutMedia {
      ${MEDIA_PROJECTION}
    },
    content[] {
      _key,
      _type == "intro" => {
        ${INTRO_PROJECTION}
      },
      _type == "keyPropositions" => {
        ${KEY_PROPOSITIONS_PROJECTION}
      },
      _type == "cafeList" => {
        ${CAFE_LIST_PROJECTION}
      },
      _type == "originStory" => {
        ${ORIGIN_STORY_PROJECTION}
      },
      _type == "sectionFullHeader" => {
        ${SECTION_FULL_HEADER_PROJECTION}
      },
      _type == "storeIntro" => {
        ${STORE_INTRO_PROJECTION}
      },
      _type == "permitRoomPromo" => {
        ${PERMIT_ROOM_PROMO_PROJECTION}
      },
      _type == "impactModule" => {
        ${IMPACT_MODULE_PROJECTION}
      },
      _type == "careersModule" => {
        ${CAREERS_MODULE_PROJECTION}
      },
      _type == "awardsAndAchievements" => {
        ${AWARDS_AND_ACHIEVEMENTS_PROJECTION}
      },
      _type == "foundingMythsSlider" => {
        ${FOUNDING_MYTHS_SLIDER_PROJECTION}
      },
      _type == "curatedRow" => {
        ${CURATED_ROW_PROJECTION}
      },
      _type == 'fullWidthFeature' => {
        ${FULL_WIDTH_FEATURE_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
      _type == 'featureAndList' => {
        ${FEATURE_AND_LIST_PROJECTION}
      },
      _type == 'form' => {
        ${FORM_PROJECTION}
      },
      _type == 'foundingMythsScroller' => {
        ${FOUNDING_MYTHS_SCROLLER_PROJECTION}
      },
      _type == 'fullWidthGuideFeature' => {
        ${FULL_WIDTH_GUIDE_FEATURE_PROJECTION}
      },
      _type == 'contentBucket' => {
        ${CONTENT_BUCKET}
      },
      _type == 'videosBlock' => {
        ${VIDEOS_BLOCK_PROJECTION}
      },
      _type == 'collectionCta' => {
       ${COLLECTION_CTA_PROJECTION}
      },
      _type == 'relatedGuideContent' => {
       ${RELATED_GUIDE_CONTENT_PROJECTION}
      },
      _type == 'featuredPostBlock' => {
        ${FEATURED_POST_BLOCK_TYPE_PROJECTION}
      },
      _type == 'productGrid' => {
        ${PRODUCT_GRID_PROJECTION}
      },
      _type == 'simpleTitle' => {
        ${SIMPLE_TITLE_PROJECTION}
      },
      _type == 'createAtHomeCallout' => {
        ${CREATE_AT_HOME_CALLOUT_PROJECTION}
      },
      _type == 'ticketCard' => {
        ${TICKET_CARD_PROJECTION}
      }
    },
    seo {
      ${SEO_PROJECTION}
    }
  }
`)

export const pagesBySlugQuery = defineQuery(`
  *[
    _type == "page" && !defined(parentPage) && slug.current == $slug && !$childSlug ||
    _type == "page" && defined(parentPage) && slug.current == $childSlug && parentPage->slug.current == $slug
  ][0] {
    _id,
    title,
    "slug": slug.current,
    passwordProtected,
    password,
    unprotectedContent {
      subtitle,
      title,
      copy[] {
      ${PORTABLE_TEXT_PROJECTION}
      },
      media {
        ${MEDIA_ONLY_PROJECTION}
      }
    },
    heroSubtitle,
    heroTitle,
    heroCopy[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    heroMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    color,
    textColor,
    hero {
      subtitle,
      title,
      copy,
      media {
        ${MEDIA_ONLY_PROJECTION}
      },
      variant,
      hideNavigationDropdown,
      "navigationDropdownLinks": navigationDropdownLinks[] {
        title,
        link-> {
        _type,
        "slug": slug.current,
        _type == "post" => {
        "category": category->title
      },
        _type == "page" => {
        "parentSlug": parent->slug.current
      }
    }
  },
    },
    content[] {
      _key,
      _type == "intro" => {
        ${INTRO_PROJECTION}
      },
      _type == "faq" => {
        ${FAQ_PROJECTION}
      },
      _type == "keyPropositions" => {
        ${KEY_PROPOSITIONS_PROJECTION}
      },
      _type == "cafeList" => {
        ${CAFE_LIST_PROJECTION}
      },
      _type == "originStory" => {
        ${ORIGIN_STORY_PROJECTION}
      },
      _type == "contactUs" => {
        ${CONTACTUS_PROJECTION}
      },
      _type == "textBlock" => {
        ${TEXT_BLOCK_PROJECTION}
      },
      _type == "sectionFullHeader" => {
        ${SECTION_FULL_HEADER_PROJECTION}
      },
      _type == "storeIntro" => {
        ${STORE_INTRO_PROJECTION}
      },
      _type == "permitRoomPromo" => {
        ${PERMIT_ROOM_PROMO_PROJECTION}
      },
      _type == "impactModule" => {
        ${IMPACT_MODULE_PROJECTION}
      },
      _type == "careersModule" => {
        ${CAREERS_MODULE_PROJECTION}
      },
      _type == "awardsAndAchievements" => {
        ${AWARDS_AND_ACHIEVEMENTS_PROJECTION}
      },
      _type == 'pullQuote' => {
        ${PULL_QUOTE_PROJECTION}
      },
      _type == 'pullQuoteSlider' => {
        ${PULL_QUOTE_SLIDER_PROJECTION}
      },
      _type == "foundingMythsSlider" => {
        ${FOUNDING_MYTHS_SLIDER_PROJECTION}
      },
      _type == "curatedRow" => {
        ${CURATED_ROW_PROJECTION}
      },
      _type == 'fullWidthFeature' => {
        ${FULL_WIDTH_FEATURE_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
      _type == 'featureAndList' => {
        ${FEATURE_AND_LIST_PROJECTION}
      },
      _type == 'form' => {
        ${FORM_PROJECTION}
      },
      _type == 'resources' => {
        ${RESOURCES_PROJECTION}
      },
      _type == 'foundingMythsScroller' => {
        ${FOUNDING_MYTHS_SCROLLER_PROJECTION}
      },
      _type == 'fullWidthGuideFeature' => {
        ${FULL_WIDTH_GUIDE_FEATURE_PROJECTION}
      },
      _type == 'ctaBanner' => {
        ${CTA_BANNER_PROJECTION}
      },
      _type == 'gallerySlider' => {
        ${GALLERY_SLIDER_PROJECTION}
      },
      _type == 'productGrid' => {
        ${PRODUCT_GRID_PROJECTION}
      },
      _type == 'videosBlock' => {
        ${VIDEOS_BLOCK_PROJECTION}
      },
      _type == 'newsletterCTA' => {
        ${NEWSLETTER_CTA_PROJECTION}
      },
      _type == 'blogMedia' => {
        ${BLOG_MEDIA_PROJECTION}
      },
      _type == 'simpleTitle' => {
        ${SIMPLE_TITLE_PROJECTION}
      },
      _type == 'createAtHomeCallout' => {
        ${CREATE_AT_HOME_CALLOUT_PROJECTION}
      },
      _type == 'ticketCard' => {
        ${TICKET_CARD_PROJECTION}
      }
    },
    contentWithoutBackgroundColor[] {
      _key,
      _type == "intro" => {
        ${INTRO_PROJECTION}
      },
      _type == "keyPropositions" => {
        ${KEY_PROPOSITIONS_PROJECTION}
      },
      _type == "cafeList" => {
        ${CAFE_LIST_PROJECTION}
      },
      _type == "originStory" => {
        ${ORIGIN_STORY_PROJECTION}
      },
      _type == "sectionFullHeader" => {
        ${SECTION_FULL_HEADER_PROJECTION}
      },
      _type == "storeIntro" => {
        ${STORE_INTRO_PROJECTION}
      },
      _type == "permitRoomPromo" => {
        ${PERMIT_ROOM_PROMO_PROJECTION}
      },
      _type == "impactModule" => {
        ${IMPACT_MODULE_PROJECTION}
      },
      _type == "careersModule" => {
        ${CAREERS_MODULE_PROJECTION}
      },
      _type == "awardsAndAchievements" => {
        ${AWARDS_AND_ACHIEVEMENTS_PROJECTION}
      },
      _type == 'pullQuote' => {
        ${PULL_QUOTE_PROJECTION}
      },
      _type == 'pullQuoteSlider' => {
        ${PULL_QUOTE_SLIDER_PROJECTION}
      },
      _type == "foundingMythsSlider" => {
        ${FOUNDING_MYTHS_SLIDER_PROJECTION}
      },
      _type == "curatedRow" => {
        ${CURATED_ROW_PROJECTION}
      },
      _type == 'fullWidthFeature' => {
        ${FULL_WIDTH_FEATURE_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
      _type == 'featureAndList' => {
        ${FEATURE_AND_LIST_PROJECTION}
      },
      _type == 'resources' => {
        ${RESOURCES_PROJECTION}
      },
      _type == 'foundingMythsScroller' => {
        ${FOUNDING_MYTHS_SCROLLER_PROJECTION}
      },
      _type == 'fullWidthGuideFeature' => {
        ${FULL_WIDTH_GUIDE_FEATURE_PROJECTION}
      },
      _type == 'ctaBanner' => {
        ${CTA_BANNER_PROJECTION}
      },
      _type == 'gallerySlider' => {
        ${GALLERY_SLIDER_PROJECTION}
      },
      _type == 'productGrid' => {
        ${PRODUCT_GRID_PROJECTION}
      },
      _type == 'blogMedia' => {
        ${BLOG_MEDIA_PROJECTION}
      },
      _type == 'textBlock' => {
        ${TEXT_BLOCK_PROJECTION}
      },
      _type == 'simpleTitle' => {
        ${SIMPLE_TITLE_PROJECTION}
      },
      _type == 'createAtHomeCallout' => {
        ${CREATE_AT_HOME_CALLOUT_PROJECTION}
    },
      _type == 'ticketCard' => {
        ${TICKET_CARD_PROJECTION}
      }
    },
    seo {
      ${SEO_PROJECTION}
    },
  }
`)

export const postBySlugQuery = defineQuery(`
  *[_type == "post" && slug.current == $slug][0] {
    _id,
    title,
    "slug": slug.current,
    category,
    subCategories -> {
      _id,
      title
    },
    flashTag->{
      ${FLASH_TAG_PROJECTION}
    },
    tags[]->{
      _id,
      title
    },
    excerpt,
    textColor,
    color,
    subCategories {
      title,
    },
    readingTime,
    storyAudio {
      ${FILE_PROJECTION}
    },
    times {
      prepTime,
      cookTime,
      totalTime,
    },
    cafe-> {
      _id,
      title,
      "slug": slug.current,
    },
    featuredMedia {
      ${MEDIA_PROJECTION}
    },
    heroVariant,
    heroMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    heroLogo {
      ${IMAGE_PROJECTION}
    },
    content[] {
      _key,
      _type == "gallerySlider" => {
        ${GALLERY_SLIDER_PROJECTION}
      },
      _type == 'pullQuote' => {
        ${PULL_QUOTE_PROJECTION}
      },
      _type == 'pullQuoteSlider' => {
        ${PULL_QUOTE_SLIDER_PROJECTION}
      },
      _type == 'gallery2up3up' => {
        _type,
        medias[] {
          ${MEDIA_PROJECTION}
        }
      },
      _type == 'textBlock' => {
        ${TEXT_BLOCK_PROJECTION}
      },
      _type == 'blogMedia' => {
        ${BLOG_MEDIA_PROJECTION}
      },
      _type == 'resources' => {
        ${RESOURCES_PROJECTION}
      },
      _type == 'nextChapter' => {
        ${NEXT_CHAPTER_PROJECTION}
      },
      _type == 'blogDivider' => {
        _type,
      },
      _type == "curatedRow" => {
        ${CURATED_ROW_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
      _type == 'form' => {
        ${FORM_PROJECTION}
      },
      _type == 'gallery' => {
        ${GALLERY_PROJECTION}
      },
      _type == 'productStoryBlock' => {
        ${PRODUCT_STORY_PROJECTION}
      },
      _type == 'heroCta' => {
        ${HERO_CTA_PROJECTION}
      },
      _type == 'methodAndIngredients' => {
        ${METHOD_AND_INGREDIENTS_PROJECTION}
      },
      _type == 'mapGuide' => {
        ${MAP_GUIDE_PROJECTION}
      },
      _type == 'videosBlock' => {
        ${VIDEOS_BLOCK_PROJECTION}
      },
      _type == 'collectionCta' => {
        ${COLLECTION_CTA_PROJECTION}
      },
      _type == 'relatedGuideContent' => {
        ${RELATED_GUIDE_CONTENT_PROJECTION}
       },
      _type == 'featuredPostBlock' => {
        ${FEATURED_POST_BLOCK_TYPE_PROJECTION}
      },
      _type == 'singleMediaCta' => {
        ${SINGLE_MEDIA_CTA_PROJECTION}
      },
      _type == 'tipsOverlay' => {
        ${TIPS_OVERLAY_PROJECTION}
      },
      _type == 'newsletterCTA' => {
        ${NEWSLETTER_CTA_PROJECTION}
      },
      _type == 'simpleTitle' => {
        ${SIMPLE_TITLE_PROJECTION}
      },
      _type == 'createAtHomeCallout' => {
        ${CREATE_AT_HOME_CALLOUT_PROJECTION}
      },
      _type == 'ticketCard' => {
        ${TICKET_CARD_PROJECTION}
      }
    },
    seo {
      ${SEO_PROJECTION}
    },
  }
`)

export const mythBySlugQuery = defineQuery(`
  *[_type == "myth" && slug.current == $slug][0] {
    _id,
    title,
    "slug": slug.current,
    excerpt,
    textColor,
    color,
    readingTime,
    storyAudio {
      ${FILE_PROJECTION}
    },
    cafe-> {
      _id,
      title,
      "slug": slug.current,
    },
    featuredMedia {
      ${MEDIA_PROJECTION}
    },
    heroMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    relatedStories {
      ${CURATED_ROW_PROJECTION}
    },
    content[] {
      _key,
      _type == 'textBlock' => {
        ${TEXT_BLOCK_PROJECTION}
      },
      _type == 'blogDivider' => {
        _type,
      },
      _type == 'blogMedia' => {
        ${BLOG_MEDIA_PROJECTION}
      },
      _type == 'nextChapter' => {
        ${NEXT_CHAPTER_PROJECTION}
      },
      _type == "gallerySlider" => {
        ${GALLERY_SLIDER_PROJECTION}
      },
      _type == 'gallery2up3up' => {
        _type,
        medias[] {
          ${MEDIA_PROJECTION}
        }
      },
      _type == 'gallery' => {
        ${GALLERY_PROJECTION}
      },
      _type == 'heroCta' => {
        ${HERO_CTA_PROJECTION}
      },
      _type == 'fullWidthMood' => {
        ${FULL_WIDTH_MOOD_PROJECTION}
      },
    },
    seo {
      ${SEO_PROJECTION}
    },
  }
`)

export const settingsQuery = defineQuery(`
  *[_type == "settings"][0]{
    notFoundPage{
      _type,
      media{
        ${MEDIA_ONLY_PROJECTION}
      },
      copy[]{
        ${PORTABLE_TEXT_PROJECTION}
      },
      links[]{
        ${CALL_TO_ACTION_PROJECTION}
      },
      seo {
        ${SEO_PROJECTION}
      }
    },
    seo {
      ${SEO_PROJECTION}
    }
  }
`)

export const bookingQuery = defineQuery(`
  *[_type == 'booking'][0]{
    _type,
    sevenroomsWidgets {
      generalWidgetUrl,
      generalOldWidgetUrl,
      isOldWidget,
      locations[] {
        _key,
        oldWidgetUrl,
        widgetUrl,
        location-> {
          _id,
          _type,
          title,
          "slug": slug.current,
        },
      }
    }
  }
`)

// export const bookingQuery = defineQuery(`{
//   'locations': *[_type in ["cafe",'permitRoom'] && defined(sevenRoomsId)]{
//     _type,
//     _id,
//     title,
//     "slug": slug.current,
//     sevenRoomsId,
//     sevenRoomsNearbyCafes[] -> {
//       _id,
//       title,
//       sevenRoomsId
//     },
//     bookingImage {
//       ${IMAGE_PROJECTION}
//     },
//     bookingMaxTableSize,
//     bookingFoundingMythTeaser-> {
//       ${POST_PROJECTION}
//     },
//     cafeGallery {
//       ${CAFE_GALLERY_PROJECTION}
//     },
//   },
//   'booking': *[_type == 'booking'][0]{
//     _type,
//     dietaryRestrictions[],
//     specialOccasions[],
//     bookingNote[] {
//       ${PORTABLE_TEXT_PROJECTION}
//     },
//     defaultBookingImage {
//       ${IMAGE_PROJECTION}
//     },
//     bookingNote[] {
//       ${PORTABLE_TEXT_PROJECTION}
//     },
//     groupBookingAlert[] {
//       ${PORTABLE_TEXT_PROJECTION}
//     },
//     sevenroomsWidgets[] {
//       _type,
//       _key,
//       venueId,
//       widgetUrl,
//       location-> {
//         _id,
//         _type,
//         title,
//         "slug": slug.current,
//       }
//     }
//   }
// }`)

const COLLECTION_PAGE_GRID_PROJECTION = /* groq */ `
  _type,
  hasLeft,
  hasLargerProductCardLeft,
  breakoutMediaBlocks{
    media{
      ${MEDIA_PROJECTION}
    },
  },
  hasLoadMoreButton,
  breakoutTextBlocks{
    quoteSource,
    text[]{
      ${PORTABLE_TEXT_PROJECTION}
    },
  },
  product[]->{
    ${PRODUCT_PROJECTION}
  }
`

const COLLABORATOR_SPOT_LIGHT_PROJECTION = /* groq */ `
  _type,
  isDisabled,
  backgroundMedia{
    ${MEDIA_ONLY_PROJECTION}
  },
  collabBranding{
    ${MEDIA_ONLY_PROJECTION}
  },
  subHeading,
  heading,
  body[]{
    ${PORTABLE_TEXT_PROJECTION}
  },
  button{
    ${CALL_TO_ACTION_PROJECTION}
  },
  supportingMedia{
    ${MEDIA_ONLY_PROJECTION}
  }
`

export const headerQuery = defineQuery(`
  *[_type == "header"][0]{
    'mainLogo': mainLogo.code,
    navSecondaryCta {
      ${CALL_TO_ACTION_PROJECTION}
    },
    mobileSecondaryNav[] {
      ${CALL_TO_ACTION_PROJECTION}
    },
    mobileFeaturedPosts[] -> {
      ${POST_PROJECTION}
    },
    explore {
      viewAll {
        ${CALL_TO_ACTION_PROJECTION}
      },
      links[] {
        link {
          ${CALL_TO_ACTION_PROJECTION}
        },
        backgroundMedia {
          ${MEDIA_ONLY_PROJECTION}
        }
      },
      featuredPosts[] -> {
        ${POST_PROJECTION}
      }
    },
    cafes {
      ${HEADER_NAV_PROJECTION}
    },
    menu {
      ${HEADER_NAV_PROJECTION}
    },
    shop {
      ${HEADER_NAV_PROJECTION}
    }
  }
`)

export const footerQuery = defineQuery(`
  *[_type == "footer"][0]{
    "alternatePreFooterPaths": alternatePreFooterPaths[]->{
      _type,
      "slug": slug.current,
      _type == "post" => {
        "category": category->title
      },
      _type == "page" => {
        "parentSlug": parent->slug.current
      }
    },
    footerNewsletter {
      logoOverlay {
        ${IMAGE_PROJECTION}
      },
      media {
        ${MEDIA_ONLY_PROJECTION}
      },
    },
    footerMenus[] {
      subtitle,
      links[] {
        _type == 'callToAction' => {
          ${CALL_TO_ACTION_PROJECTION}
        },
        _type == 'dropdown' => {
          _type,
          title,
          links[] {
            ${CALL_TO_ACTION_PROJECTION}
          }
        }
      }
    },
    mobileFooterMenus[] {
      subtitle,
      links[] {
        _type == 'callToAction' => {
          ${CALL_TO_ACTION_PROJECTION}
        },
        _type == 'dropdown' => {
          _type,
          title,
          links[] {
            ${CALL_TO_ACTION_PROJECTION}
          }
        }
      }
    },
    legalMessage,
    legalLinks[] {
      ${CALL_TO_ACTION_PROJECTION}
    },
    socialLinks {
      instagram,
      youtube,
      x,
      tikTok,
      linkedIn,
    },
    subFooter {
    media {
        ${MEDIA_ONLY_PROJECTION}
      },
      text
    }
  }
`)

export const cafeBySlugQuery = defineQuery(`
  *[_type == "cafe" && slug.current == $slug][0] {
    _id,
    title,
    "slug": slug.current,
    cafeLogo,
    layoutVariant,
    hero {
      ${CAFE_HERO_PROJECTION}
    },
    foundingMythTeaser {
      ${FOUNDING_MYTH_TEASER_PROJECTION}
    },
    foundingMythTeaserSecondaryImage {
      ${IMAGE_PROJECTION}
    },
    foundingMythTeaserBattersea {
      image {
        ${IMAGE_PROJECTION}
      },
      copy[] {
        character,
        phrase,
      },
      animatedImage1 {
        ${IMAGE_PROJECTION}
      },
      animatedImage2 {
        ${IMAGE_PROJECTION}
      },
      animatedImage3 {
        ${IMAGE_PROJECTION}
      },
      myth-> {
        ${CAFE_MYTH_TEASER_PROJECTION}
      }
    },
    foundingMythTeaserCanaryWharf {
      image {
        ${IMAGE_PROJECTION}
      },
      secondaryImage {
        ${IMAGE_PROJECTION}
      },
      intro[],
      title,
      copy[] {
        character,
        phrase,
      },
      myth-> {
        ${CAFE_MYTH_TEASER_PROJECTION}
      }
    },
    welcomeTo {
      ${CAFE_TEXT_MEDIA_PROJECTION}
    },
    foodAndDrink {
      ${CAFE_TEXT_MEDIA_PROJECTION}
    },
    specialsFood {
      ${CAFE_SPECIALS_TEXT_MEDIA_PROJECTION}
    },
    specialsDrinks {
      ${CAFE_SPECIALS_TEXT_MEDIA_PROJECTION}
    },
    specialsDrinksCocktails {
      subtitle,
      drinks[] {
        _type,
        name,
        copy[] {
          ${PORTABLE_TEXT_PROJECTION}
        },
      }
    },
    fullWidthMediaCopy {
      ${FULL_WIDTH_MEDIA_COPY_PROJECTION}
    },
    kensingtonHouseBand {
      preTitle,
      title,
      postTitle,
      copy[] {
        ${PORTABLE_TEXT_PROJECTION}
      },
      illustration {
        ${IMAGE_PROJECTION}
      }
    },
    reservations {
      ${RESERVATIONS_PROJECTION}
    },
    reservationsCanaryWharf {
      bookTableMedia {
        ${MEDIA_ONLY_PROJECTION}
      },
      groupBookingsMedia {
        ${MEDIA_ONLY_PROJECTION}
      },
      secondaryCopy {
        ${PORTABLE_TEXT_PROJECTION}
      }
    },
    fourUpAlbum[] {
      ${IMAGE_PROJECTION}
    },
    cafeGallery {
      ${CAFE_GALLERY_PROJECTION}
    },
    visitorInfo {
      ${VISITOR_INFO_PROJECTION}
    },
    featureAndList {
      ${FEATURE_AND_LIST_PROJECTION}
    },
    signposting {
      ${KEY_PROPOSITIONS_PROJECTION}
    },
    seo {
      ${SEO_PROJECTION}
    },
  }
`)

export const menuBySlugQuery = defineQuery(`
  *[_type == "menu" && slug.current == $slug][0] {
    ${MENU_PAYLOAD_PROJECTION}
  }
`)

export const menuNavQuery = defineQuery(`
  *[_type == "menu"] | order(orderRank) {
    _type,
    _id,
    'title': select(
      isParent == true => title,
      isParent == false => parentMenu->title
    ),
    "slug": slug.current,
    isParent,
    color,
    textColor,
    menuLayout,
    "filters": *[
      _type == "menu" && (
      ^.isParent == true && isParent == true ||
      ^.isParent == false && isParent == false && currentLocation._ref == ^.currentLocation._ref
    )] | order(orderRank) {
      _type,
      _id,
      'title': select(
        isParent == true => title,
        isParent == false => parentMenu->title
      ),
      "slug": slug.current
    },
    'allergens': *[_type == 'allergen'] {
      _id,
      title,
    },
  }
`)

export const menuByMenuSlugAndCafeSlugQuery = defineQuery(`
  *[_type == "menu" && slug.current == $slug && count(locations[cafe->slug.current == $cafeSlug]) > 0][0] {
    ${MENU_PAYLOAD_PROJECTION}
  }
`)

export const eventBySlugQuery = defineQuery(`
  *[_type == "event" && slug.current == $slug][0] {
    _id,
    title,
    "slug": slug.current,
    tags[]->{
      _id,
      title
    },
    excerpt,
    textColor,
    color,
    layout,
    date,
    isDateRange,
    startDate,
    endDate,
    time,
    price,
    availabilityText,
    address,
    eventCta {
      ${CALL_TO_ACTION_PROJECTION}
    },
    featuredMedia {
      ${MEDIA_PROJECTION}
    },
    heroMedia {
      ${MEDIA_ONLY_PROJECTION}
    },
    sections[] {
      _key,
      anchorLinkTitle,
      subtitle,
      title,
      media {
        ${MEDIA_PROJECTION}
      },
      content[] {
        _key,
        _type == 'textBlock' => {
          ${TEXT_BLOCK_PROJECTION}
        },
        _type == 'blogMedia' => {
          ${BLOG_MEDIA_PROJECTION}
        },
        _type == 'accordion' => {
          ${ACCORDION_PROJECTION}
        },
      }
    },
    gallery {
      ${GALLERY_SLIDER_PROJECTION}
    },
    fullWidthMood {
      ${FULL_WIDTH_MOOD_PROJECTION}
    },
    curatedRow {
      ${CURATED_ROW_PROJECTION}
    },
    seo {
      ${SEO_PROJECTION}
    },
  }
`)

export const productBySlugQuery = defineQuery(`
  *[_type == "product" && store.slug.current == $slug][0]{
    _id,
    _type,
    'title': store.title,
    'slug': store.slug.current,
    withImageSlider,
    collections[]-> {
      'title': titleProxy,
      'slug': slugProxy,
    },
    isFreshProduct,
    isCheckAge,
    allergens[]-> {
      title,
    },
    flashTag-> {
      ${FLASH_TAG_PROJECTION}
    },
    subscribeDiscount,
    subscriptionUpsell[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    servingNote,
    description[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    whatsIncluded[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    deliveryInfo[] {
      ${PORTABLE_TEXT_PROJECTION}
    },
    productImages[] {
      ${IMAGE_PROJECTION}
    },
    ${PRODUCT_STORE_FIELDS_PROJECTION},
      accordions[] {
        heading,
        content[]{
          ${PORTABLE_TEXT_PROJECTION}
      },
    },
    suggestedProducts {
      title,
      products[]-> {
        ${PRODUCT_PROJECTION},
      }
    },
    storyBlock{
      copy[] {
        ${PORTABLE_TEXT_PROJECTION}
      },
      note[] {
        ${PORTABLE_TEXT_PROJECTION}
      }
    },
    textAndMedia {
      variant,
      media {
        ${MEDIA_PROJECTION}
      },
      smallMedia {
        ${MEDIA_PROJECTION}
      },
      text[] {
        ${PORTABLE_TEXT_PROJECTION}
      }
    },
    productVideo {
      ${VIDEO_PROJECTION}
    },
    audioPlaylistBlock{
      ${AUDIO_PLAYLIST_BLOCK_PROJECTION}
    },
    fullWidthMood {
      ${FULL_WIDTH_MOOD_PROJECTION}
    },
    curatedRow {
      ${CURATED_ROW_PROJECTION}
    },
    seo {
      ${SEO_PROJECTION}
    },
  }
`)
export const permitRoomsPageQuery = defineQuery(`
  *[_type == "permitRooms"][0] {
    _id,
    title,
    slug,
    seo {
      ${SEO_PROJECTION}
    }
  }
`)
export const collectionsPageQuery = defineQuery(`
  *[_type == "collection" && store.slug.current == $slug][0]{
    _id,
    _type,
    "breadcrumbTitle":store.title,
    content[]{
      _key,
      _type == "lifestyleShoppingModule" => {
        ${LIFESTYLE_SHOPPING_MODULE_PROJECTION}
      },
      _type == "collectionPageGrid" => {
        ${COLLECTION_PAGE_GRID_PROJECTION}
      },
      _type == "collaboratorSpotlight" => {
        ${COLLABORATOR_SPOT_LIGHT_PROJECTION}
      },
      _type == "collectionSlider" => {
        ${COLLECTION_SLIDER_PROJECTION}
      },
    },
    seo {
      ${SEO_PROJECTION}
    }
  }
`)

export const cartQuery = defineQuery(`
  *[_type == "cart"][0]{
    freeShippingThreshold,
  }
`)

export const storeSinglePageBySlugQuery = defineQuery(`
  *[_type == "storePage" && slug.current == $slug][0] {
    _id,
    title,
    "slug": slug.current,
    shopifyUrl,
    seo {
      ${SEO_PROJECTION}
    },
  }
`)
